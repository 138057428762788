export default [
    'members',
    'website',
    'nal-help',
    'exe-video',
    'exe-article',
    'exe-faq',
    'ezekia-news',
    'ezekia-faq',
    'website-news',
    'exe-monthly-email',
    'exe-dashboards',
];